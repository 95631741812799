/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export * from 'SourceComponent/Header/Header.config';

export const CURRENCY_PLN = 'PLN';
export const LANG_CODE_PL = 'pl';
export const SPACER = 12;
