/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export const GET_NONAME_STORE_CONFIG = 'GET_NONAME_STORE_CONFIG';

/** @namespace Pwa/Store/NoNameStore/Action/updateNoNameStoreConfig */
export const updateNoNameStoreConfig = (config) => ({
    type: GET_NONAME_STORE_CONFIG,
    config
});
