/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    GET_BLOG_POST_DETAILS, GET_BLOG_POST_LISTING
} from './BlogPost.action';

/** @namespace Pwa/Store/BlogPost/Reducer/getInitialState */
export const getInitialState = () => ({
    postDetails: {},
    postListing: {},
    totalPages: 0
});

/** @namespace Pwa/Store/BlogPost/Reducer/BlogPostReducer */
export const BlogPostReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case GET_BLOG_POST_DETAILS:
        const { postDetails } = action;

        return {
            ...state,
            postDetails
        };

    case GET_BLOG_POST_LISTING:
        const { postListing, totalPages } = action;

        return {
            ...state,
            postListing,
            totalPages
        };

    default:
        return state;
    }
};

export default BlogPostReducer;
