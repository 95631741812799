/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CLEAR_SHIPPING_FORM_FIELDS,
    DESIRE_SAVE_ADDRESS_IN_ADDRESS_BOOK,
    SET_ADDRESS_ID,
    SET_PAYMENT_METHOD,
    TOGGLE_CUSTOM_ADDRESS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_FOREIGN_NUMBER,
    UPDATE_FREE_CATALOG_QTY,
    UPDATE_ORDER_COMMENT,
    UPDATE_SHIPPING_FIELDS,
    UPDATE_SHIPPING_FORM_FIELDS
} from './Checkout.action';

/** @namespace Pwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    shippingFormFields: {},
    email: '',
    isEmailAvailable: true,
    orderComment: '',
    foreignNumber: '',
    freeCatalogQty: 0,
    isSaveAddressInAddressBook: false,
    isCustomAddressExpanded: false,
    paymentCode: '',
    addressID: null
});

/** @namespace Pwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case CLEAR_SHIPPING_FORM_FIELDS:

        return {
            ...state,
            shippingFormFields: { },
            isCustomAddressExpanded: false
        };

    case UPDATE_SHIPPING_FORM_FIELDS:
        const { name, value } = action;

        return {
            ...state,
            shippingFormFields: {
                ...state.shippingFormFields,
                [name]: value
            }
        };

    case TOGGLE_CUSTOM_ADDRESS:
        return {
            ...state,
            isCustomAddressExpanded: !state.isCustomAddressExpanded
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    case UPDATE_ORDER_COMMENT:
        const { orderComment } = action;

        return {
            ...state,
            orderComment
        };

    case UPDATE_FOREIGN_NUMBER:
        const { foreignNumber } = action;

        return {
            ...state,
            foreignNumber
        };

    case UPDATE_FREE_CATALOG_QTY:
        const { freeCatalogQty } = action;

        return {
            ...state,
            freeCatalogQty
        };

    case DESIRE_SAVE_ADDRESS_IN_ADDRESS_BOOK:

        return {
            ...state,
            isSaveAddressInAddressBook: !state.isSaveAddressInAddressBook
        };

    case SET_PAYMENT_METHOD:
        const { code } = action;

        return {
            ...state,
            paymentCode: code
        };

    case SET_ADDRESS_ID:
        const { addressID } = action;

        return {
            ...state,
            addressID
        };
    default:
        return state;
    }
};

export default CheckoutReducer;
