/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    GET_BLOG_CATEGORIES, GET_BLOG_CATEGORY_DETAIL
} from './BlogCategories.action';

/** @namespace Pwa/Store/BlogCategories/Reducer/getInitialState */
export const getInitialState = () => ({
    categories: {},
    categoryDetail: {}
});

/** @namespace Pwa/Store/BlogCategories/Reducer/BlogCategoriesReducer */
export const BlogCategoriesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case GET_BLOG_CATEGORIES:
        const { categories } = action;

        return {
            ...state,
            categories
        };

    case GET_BLOG_CATEGORY_DETAIL:
        const { categoryDetail } = action;

        return {
            ...state,
            categoryDetail
        };

    default:
        return state;
    }
};

export default BlogCategoriesReducer;
