/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import { calculateOfferTotalPrices } from '../../util/Offer/Offer';
import {
    CHANGE_OFFER_GLOBAL_PARAM,
    GET_BUSINESS_CONDITION_LIST,
    GET_OFFERS_LIST,
    SET_REFRESH_LIST,
    TOGGLE_CUSTOM_PRICE,
    UPDATE_ACTIVE_OFFER,
    UPDATE_ACTIVE_OFFER_DETAILS,
    UPDATE_ITEM,
    UPDATE_ITEM_DESCRIPTION,
    UPDATE_NAME,
    UPDATE_OFFER,
    UPDATE_OFFER_ADDRESS_ID,
    UPDATE_OFFER_CONFIG,
    UPDATE_OFFER_FIELD,
    UPDATE_OFFERS_COUNT
} from './Offer.action';

/** @namespace Pwa/Store/Offer/Reducer/getInitialState */
export const getInitialState = () => ({
    activeOffer: {},
    activeOfferId: 0,
    businessConditions: [],
    config: {},
    incrementId: 1,
    isCustomPrice: false,
    isLoading: true,
    itemsCount: 0,
    offer: {},
    offers: [],
    offersCount: 0,
    refreshList: false,
    addressId: null
});

/** @namespace Pwa/Store/Offer/Reducer/OfferReducer */
export const OfferReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case GET_OFFERS_LIST:
        const { offersList, status } = action;

        return {
            ...state,
            isLoading: status,
            offers: offersList,
            refreshList: false
        };

    case SET_REFRESH_LIST:
        return {
            ...state,
            refreshList: true
        };

    case GET_BUSINESS_CONDITION_LIST:
        const { businessConditions } = action;

        return { ...state, businessConditions };

    case TOGGLE_CUSTOM_PRICE:
        const { calculationType } = action;

        return { ...state, offer: { ...state.offer, calculation_type: calculationType } };

    case UPDATE_ACTIVE_OFFER_DETAILS:
        const { activeOfferId, itemsCount } = action;

        return {
            ...state,
            activeOfferId,
            itemsCount
        };

    case UPDATE_NAME:
        const { name } = action;

        return {
            ...state,
            offer: {
                ...state.offer,
                name
            }
        };

    case UPDATE_OFFER_FIELD:
        const { field, value } = action;

        return {
            ...state,
            offer: {
                ...state.offer,
                [field]: value
            }
        };

    case UPDATE_ACTIVE_OFFER:
        const { offer: activeOffer } = action;

        return {
            ...state,
            activeOffer
        };
    case UPDATE_OFFER:
        const { offer } = action;

        return {
            ...state,
            offer
        };

    case UPDATE_OFFERS_COUNT:
        const { offersCount } = action;

        return {
            ...state,
            offersCount
        };

    case UPDATE_OFFER_CONFIG:
        const { config } = action;

        return {
            ...state,
            config
        };

    case UPDATE_ITEM:
        const { item } = action;
        const { offer: oldOffer } = state;

        const updatedOffer = {
            ...oldOffer,
            items: oldOffer.items.map(({ id, ...rest }) => {
                if (id === item.id) {
                    return item;
                }

                return { id, ...rest };
            })
        };

        return {
            ...state,
            offer: calculateOfferTotalPrices(updatedOffer)
        };

    case UPDATE_ITEM_DESCRIPTION:
        const { description, itemId } = action;
        const { offer: previousOffer } = state;

        const updatedOfferWithNewItemDescription = {
            ...previousOffer,
            items: previousOffer.items.map(({ id, ...rest }) => {
                if (itemId === id) {
                    return { ...rest, id, description };
                }

                return { id, ...rest };
            })
        };

        return {
            ...state,
            offer: updatedOfferWithNewItemDescription
        };
    case UPDATE_OFFER_ADDRESS_ID:
        const { addressId } = action;

        return {
            ...state,
            addressId
        };
    case CHANGE_OFFER_GLOBAL_PARAM:
        const { key, value: fieldValue } = action;

        return {
            ...state,
            offer: {
                ...state.offer,
                [key]: fieldValue
            }
        };

    default:
        return state;
    }
};

export default OfferReducer;
