/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import currencyMap from 'SourceUtil/Price/Price.config';

/** @namespace Pwa/Util/Price/renderFormattedPrice */
export const renderFormattedPrice = (price, currency = 'PLN') => `${price.toFixed(2).replace('.', ',')} ${currencyMap[currency]}`;

/** @namespace Pwa/Util/Price/formatPriceRange */
export const formatPriceRange = (labelPrice) => `${labelPrice.replace(/\./g, ',')}`;

/** @namespace Pwa/Util/Price/getSeparatedNumber */
export const getSeparatedNumber = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');

/** @namespace Pwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return getSeparatedNumber(new Intl.NumberFormat(language, { style: 'currency', currency }).format(price));
};

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Pwa/Util/Price/roundPrice */
export const roundPrice = (price) => parseFloat(price).toFixed(2);
