/* eslint-disable array-func/no-unnecessary-this-arg */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { GET_DETAILED_ORDER_LIST, SET_DETAILED_ORDER_PAGE_INFO } from './DetailedOrder.action';

export const DETAILED_ORDERS = 'detailed_orders';

/** @namespace Pwa/Store/DetailedOrder/Reducer/getFormattedDate */
export const getFormattedDate = (rawDate = '') => {
    const date = new Date(rawDate.replace(/\s/, 'T'));
    const RADIX = 10;

    const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);

    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);

    return `${day}/${month}/${date.getFullYear()}`;
};

/** @namespace Pwa/Store/DetailedOrder/Reducer/formatOrders */
export const formatOrders = (orders) => orders.map((order) => {
    const { order_date } = order;
    const formattedDate = getFormattedDate(order_date);

    return {
        ...order,
        order_date: formattedDate
    };
}, []);

export const detailedOrderList = BrowserDatabase.getItem(DETAILED_ORDERS) || [];

/** @namespace Pwa/Store/DetailedOrder/Reducer/getInitialState */
export const getInitialState = () => ({
    detailedOrderList,
    isLoading: !detailedOrderList.length,
    pageInfo: {}
});

/** @namespace Pwa/Store/DetailedOrder/Reducer/DetailedOrderReducer */
export const DetailedOrderReducer = (state = getInitialState(), action) => {
    const {
        type, detailedOrderList, pageInfo, status
    } = action;

    switch (type) {
    case GET_DETAILED_ORDER_LIST:
        const formattedOrders = formatOrders(detailedOrderList);
        BrowserDatabase.setItem(
            formattedOrders,
            DETAILED_ORDERS,
            ONE_MONTH_IN_SECONDS
        );

        return {
            ...state,
            isLoading: status,
            detailedOrderList: formattedOrders
        };

    case SET_DETAILED_ORDER_PAGE_INFO:
        return {
            ...state,
            pageInfo
        };

    default:
        return state;
    }
};

export default DetailedOrderReducer;
