/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export const CHANGE_OFFER_GLOBAL_PARAM = 'CHANGE_OFFER_GLOBAL_PARAM';
export const GET_BUSINESS_CONDITION_LIST = 'GET_BUSINESS_CONDITION_LIST';
export const GET_OFFERS_LIST = 'GET_OFFERS_LIST';
export const SET_REFRESH_LIST = 'SET_REFRESH_LIST';
export const UPDATE_ACTIVE_OFFER_DETAILS = 'UPDATE_ACTIVE_OFFER_DETAILS';
export const UPDATE_ACTIVE_OFFER = 'UPDATE_ACTIVE_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const UPDATE_OFFER_FIELD = 'UPDATE_OFFER_FIELD';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_SELECTED_BUSINESS_CONDITION = 'UPDATE_SELECTED_BUSINESS_CONDITION';
export const UPDATE_OFFER_CONFIG = 'UPDATE_OFFER_CONFIG';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_DESCRIPTION = 'UPDATE_ITEM_DESCRIPTION';
export const TOGGLE_CUSTOM_PRICE = 'TOGGLE_CUSTOM_PRICE';
export const UPDATE_OFFERS_COUNT = 'UPDATE_OFFERS_COUNT';
export const UPDATE_OFFER_ADDRESS_ID = 'UPDATE_OFFER_ADDRESS_ID';

/** @namespace Pwa/Store/Offer/Action/getOffersList */
export const getOffersList = (offersList, status) => ({
    type: GET_OFFERS_LIST,
    offersList,
    status
});

/** @namespace Pwa/Store/Offer/Action/toggleCustomPrice */
export const toggleCustomPrice = (calculationType) => ({
    type: TOGGLE_CUSTOM_PRICE,
    calculationType
});

/** @namespace Pwa/Store/Offer/Action/updateActiveOffer */
export const updateActiveOffer = (offer) => ({
    type: UPDATE_ACTIVE_OFFER,
    offer
});

/** @namespace Pwa/Store/Offer/Action/updateOffer */
export const updateOffer = (offer) => ({
    type: UPDATE_OFFER,
    offer
});

/** @namespace Pwa/Store/Offer/Action/updateOfferField */
export const updateOfferField = (field, value) => ({
    type: UPDATE_OFFER_FIELD,
    field,
    value
});

/** @namespace Pwa/Store/Offer/Action/updateName */
export const updateName = (name) => ({
    type: UPDATE_NAME,
    name
});

/** @namespace Pwa/Store/Offer/Action/updateOfferConfig */
export const updateOfferConfig = (config) => ({
    type: UPDATE_OFFER_CONFIG,
    config
});

/** @namespace Pwa/Store/Offer/Action/updateOffersCount */
export const updateOffersCount = (offersCount) => ({
    type: UPDATE_OFFERS_COUNT,
    offersCount
});

/** @namespace Pwa/Store/Offer/Action/updateItem */
export const updateItem = (item) => ({
    type: UPDATE_ITEM,
    item
});

/** @namespace Pwa/Store/Offer/Action/updateItemDescription */
export const updateItemDescription = (description, itemId) => ({
    type: UPDATE_ITEM_DESCRIPTION,
    description,
    itemId
});

/** @namespace Pwa/Store/Offer/Action/getBusinessConditionsList */
export const getBusinessConditionsList = (businessConditions) => ({
    type: GET_BUSINESS_CONDITION_LIST,
    businessConditions
});

/** @namespace Pwa/Store/Offer/Action/updateActiveOfferDetails */
export const updateActiveOfferDetails = (offer) => ({
    type: UPDATE_ACTIVE_OFFER_DETAILS,
    itemsCount: offer.items_count,
    activeOfferId: offer.offer_id ?? offer.id
});

/** @namespace Pwa/Store/Offer/Action/updateOfferAddressId */
export const updateOfferAddressId = (addressId) => ({
    type: UPDATE_OFFER_ADDRESS_ID,
    addressId
});

/** @namespace Pwa/Store/Offer/Action/changeOfferGlobalParam */
export const changeOfferGlobalParam = (key, value) => ({
    type: CHANGE_OFFER_GLOBAL_PARAM,
    key,
    value
});

/** @namespace Pwa/Store/Offer/Action/setRefreshList */
export const setRefreshList = () => ({
    type: SET_REFRESH_LIST
});
