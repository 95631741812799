/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export const GET_DETAILED_ORDER_LIST = 'GET_DETAILED_ORDER_LIST';
export const SET_DETAILED_ORDER_PAGE_INFO = 'SET_DETAILED_ORDER_PAGE_INFO';

/** @namespace Pwa/Store/DetailedOrder/Action/getDetailedOrderList */
export const getDetailedOrderList = (detailedOrderList, status) => ({
    type: GET_DETAILED_ORDER_LIST,
    detailedOrderList,
    status
});

/** @namespace Pwa/Store/DetailedOrder/Action/setOrdersPageInfo */
export const setOrdersPageInfo = (pageInfo) => ({
    type: SET_DETAILED_ORDER_PAGE_INFO,
    pageInfo
});
