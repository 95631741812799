/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export const END_DATE_LENGTH = 10;

/** @namespace Pwa/Util/Offer/formatNumberToPrice */
export const formatNumberToPrice = (number) => (parseInt(number * 100, 10) / 100).toFixed(2);

/** @namespace Pwa/Util/Offer/formatOfferPrices */
export const formatOfferPrices = (offer) => ({
    ...offer,
    // eslint-disable-next-line max-len
    items: offer.items.map(({ item_parts, ...rest }) => ({ ...rest, item_parts: item_parts.map(({ custom_price, ...rest }) => ({ ...rest, custom_price: formatNumberToPrice(custom_price) })) })),
    shipping_price: formatNumberToPrice(offer.shipping_price)
});

/** @namespace Pwa/Util/Offer/calculateOfferTotalPrices */
export const calculateOfferTotalPrices = (offer) => {
    let customize_price = 0;
    let total_global_price = 0;
    let your_profit = 0;

    offer.items.forEach(({ profit, total_custom_price, total_price }) => {
        your_profit += profit;
        total_global_price += total_price;
        customize_price += total_custom_price;
    });

    return {
        ...offer,
        your_profit,
        total_global_price,
        customize_price
    };
};

/** @namespace Pwa/Util/Offer/calculateItemPrices */
export const calculateItemPrices = (item) => {
    const { item_parts, qty, total_price } = item;

    let totalCustomPrice = 0;

    item_parts.forEach(({ custom_price }) => {
        const price = custom_price !== '' ? custom_price : 0;
        totalCustomPrice += parseFloat(price);
    });

    const profit = Math.round(((totalCustomPrice * qty) - total_price) * 100) / 100;

    return { ...item, total_custom_price: totalCustomPrice * qty, profit };
};

/** @namespace Pwa/Util/Offer/calculatePriceFromMargin */
export const calculatePriceFromMargin = (item_part, margin) => {
    const { price } = item_part;
    const custom_price = Math.round((price * ((100 + margin) / 100)) * 100) / 100;
    const profit = custom_price - price;

    return {
        ...item_part, custom_price, margin, profit
    };
};

/** @namespace Pwa/Util/Offer/formatDate */
export const formatDate = (date) => date?.slice(0, END_DATE_LENGTH)?.split('-')?.reverse()?.join('.');

/** @namespace Pwa/Util/Offer/debounce */
export function debounce(callback, delay) {
    return (...args) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => callback.apply(this, args), delay);
    };
}
