/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    GET_NONAME_STORE_CONFIG
} from './NoNameStore.action';

/** @namespace Pwa/Store/NoNameStore/Reducer/getInitialState */
export const getInitialState = () => ({
    config: {}
});

/** @namespace Pwa/Store/NoNameStore/Reducer/NoNameStoreReducer */
export const NoNameStoreReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case GET_NONAME_STORE_CONFIG:
        const { config } = action;

        return {
            ...state,
            config
        };

    default:
        return state;
    }
};

export default NoNameStoreReducer;
