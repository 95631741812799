/** * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';
export const UPDATE_ORDER_COMMENT = 'UPDATE_ORDER_COMMENT';
export const UPDATE_FOREIGN_NUMBER = 'UPDATE_FOREIGN_NUMBER';
export const UPDATE_FREE_CATALOG_QTY = 'UPDATE_FREE_CATALOG_QTY';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const DESIRE_SAVE_ADDRESS_IN_ADDRESS_BOOK = 'DESIRE_SAVE_ADDRESS_IN_ADDRESS_BOOK';
export const SET_ADDRESS_ID = 'SET_ADDRESS_ID';
export const TOGGLE_CUSTOM_ADDRESS = 'TOGGLE_CUSTOM_ADDRESS';
export const UPDATE_SHIPPING_FORM_FIELDS = 'UPDATE_SHIPPING_FORM_FIELDS';
export const CLEAR_SHIPPING_FORM_FIELDS = 'CLEAR_SHIPPING_FORM_FIELDS';

/** @namespace Pwa/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields) => ({
    type: UPDATE_SHIPPING_FIELDS,
    shippingFields
});

/** @namespace Pwa/Store/Checkout/Action/updateShippingFormFields */
export const updateShippingFormFields = (name, value) => ({
    type: UPDATE_SHIPPING_FORM_FIELDS,
    name,
    value
});

/** @namespace Pwa/Store/Checkout/Action/clearShippingFormFields */
export const clearShippingFormFields = () => ({
    type: CLEAR_SHIPPING_FORM_FIELDS
});

/** @namespace Pwa/Store/Checkout/Action/updateEmail */
export const updateEmail = (email) => ({
    type: UPDATE_EMAIL,
    email
});

/** @namespace Pwa/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable) => ({
    type: UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable
});

/** @namespace Pwa/Store/Checkout/Action/updateOrderComment */
export const updateOrderComment = (orderComment) => ({
    type: UPDATE_ORDER_COMMENT,
    orderComment
});

/** @namespace Pwa/Store/Checkout/Action/updateForeignNumber */
export const updateForeignNumber = (foreignNumber) => ({
    type: UPDATE_FOREIGN_NUMBER,
    foreignNumber
});

/** @namespace Pwa/Store/Checkout/Action/updateFreeCatalogQty */
export const updateFreeCatalogQty = (freeCatalogQty) => ({
    type: UPDATE_FREE_CATALOG_QTY,
    freeCatalogQty
});

/** @namespace Pwa/Store/Checkout/Action/toggleCustomAddress */
export const toggleCustomAddress = () => ({
    type: TOGGLE_CUSTOM_ADDRESS
});

/** @namespace Pwa/Store/Checkout/Action/changeDesireSaveAddressInAddressBook */
export const changeDesireSaveAddressInAddressBook = () => ({
    type: DESIRE_SAVE_ADDRESS_IN_ADDRESS_BOOK
});

/** @namespace Pwa/Store/Checkout/Action/setPaymentMethod */
export const setPaymentMethod = (code) => ({
    type: SET_PAYMENT_METHOD,
    code
});

/** @namespace Pwa/Store/Checkout/Action/setAddressID */
export const setAddressID = (addressID) => ({
    type: SET_ADDRESS_ID,
    addressID
});
